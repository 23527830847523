/* eslint-disable no-param-reassign */
import ChatContainer from './chatContainer';
import ZoidWidgetComponent from './zoid';
import storageAPI from './lib/webStorageAPI';
import cs, { CHAT_EVENTS } from './lib/chatSocketAPI';
import { getInitWidgetConfig } from './services';
import { processInitConfig } from './utils';

function extendObject(a, b) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in b) {
    // eslint-disable-next-line no-param-reassign
    if (Object.prototype.hasOwnProperty.call(b, key)) a[key] = b[key];
  }
  return a;
}

const supportedAPI = [
  'init',
  'show',
  'hide',
  'destroy',
  'custom_styles',
  'toggle-open',
  'popup_styles',
  'open-with-params',
];

class WidgetAPI {
  constructor() {
    this.injectCSS();
    this.configurations = {};
    this.chatContainer = null;
    this.componentWidget = null;
    this.emitChatSocketEvent = null;
  }

  injectCSS = () => {
    const link = document.createElement('link');

    link.type = 'text/css';
    link.rel = 'stylesheet';

    if (process.env.ENV !== 'development') {
      link.href = `${process.env.CDN_PATH}/widget.css`;
    }

    const { head } = document;
    head.appendChild(link);
  };

  initChatSocket = ({ referrer, sessionId, initParams = {} }) => {
    cs.connect({
      referrer,
      conversation_id: sessionId,
      initParams,
    });

    cs.socket.io.on(CHAT_EVENTS.RECONNECT, () => {
      cs.handshake({
        referrer,
        conversation_id: sessionId,
      });
      this.emitChatSocketEvent({ eventName: 'reconnect' });
    });

    cs.on(CHAT_EVENTS.CONNECT_ERROR, (data) => {
      if (data?.type && data.type === 'TransportError') {
        this.emitChatSocketEvent({
          eventName: 'network_error',
          eventData: data,
        });
        return;
      }

      this.emitChatSocketEvent({ eventName: 'connect_error', eventData: data });
    });

    cs.on(CHAT_EVENTS.VISITOR_HISTORY, (data = {}) => {
      this.emitChatSocketEvent({
        eventName: 'init',
        eventData: {
          chatHistory: data?.conversation,
          aiDisabled: data?.ai_disabled,
          activeConversationSessionId: data?.active_conversation_session_id,
        },
      });
    });

    cs.on(CHAT_EVENTS.VISITOR_META_EVENT, (data = {}) => {
      this.emitChatSocketEvent({
        eventName: 'visitor_meta_event',
        eventData: { ...data.conversationUpdate },
      });
    });

    cs.on(CHAT_EVENTS.AI_RESPONSE, (message = {}) => {
      this.emitChatSocketEvent({
        eventName: 'ai_response',
        eventData: { ...message },
      });
    });

    cs.on(CHAT_EVENTS.PLATFORM_INPUT, (message = {}) => {
      this.emitChatSocketEvent({
        eventName: 'platform_message',
        eventData: { ...message },
      });
    });

    cs.on(CHAT_EVENTS.CSAT_REQUEST, (message = {}) => {
      this.emitChatSocketEvent({
        eventName: 'csat_request',
        eventData: { ...message },
      });
    });

    cs.on(CHAT_EVENTS.VISITOR_ERROR, (data = {}) => {
      this.emitChatSocketEvent({
        eventName: 'visitor_error',
        eventData: { ...data },
      });
    });

    cs.on(CHAT_EVENTS.AI_RESPONSE_STREAM, (data = {}) => {
      this.emitChatSocketEvent({
        eventName: 'ai_response_stream',
        eventData: { ...data },
      });
    });

    cs.on(CHAT_EVENTS.CONNECT, () => {
      if (process.env.ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(`CONNECTED SOCKET: ${cs.socket.id}`);
      }

      this.emitChatSocketEvent({
        eventName: 'connect',
      });
    });

    cs.on(CHAT_EVENTS.DISCONNECT, (reason = '') => {
      if (process.env.ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(`DISCONNECTED SOCKET: ${cs.id}`);
        // eslint-disable-next-line no-console
        console.log(`DISCONNECT REASON: ${reason}`);
      }

      this.emitChatSocketEvent({
        eventName: 'disconnect',
      });
    });
  };

  init = async (config) => {
    try {
      let initConfig = await getInitWidgetConfig();

      initConfig = processInitConfig(initConfig);

      if (!initConfig?.isVisible) return;

      this.configurations = extendObject(this.configurations, {
        ...config,
        cacheExpiryTime: initConfig.conversationIdExpiryTime,
      });

      // If chat container is already initialized, just update its props passed to it
      if (this.chatContainer) {
        const props = this.getIframeProps(this.configurations);
        this.updateIframeProps(props);
        return;
      }

      this.chatContainer = new ChatContainer(this.configurations);
      this.chatContainer.initialize(initConfig);

      this.mountIframe(this.chatContainer.chatApp.ele.id);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error?.message || error);
    }
  };

  upsertStorageItem = (key, item) => {
    storageAPI.setItem(key, item);
  };

  getStorageItem = (key) => {
    const item = storageAPI.getItem(key);
    return item;
  };

  getIframeProps = (props = {}) => ({
    // imageURL: props?.imageURL,
    // spaceId: props?.spaceId,
    referrer: window.location.href,

    getStorageItem: this.getStorageItem,
    upsertStorageItem: this.upsertStorageItem,

    initChatSocket: this.initChatSocket,
    sendMessage: cs.sendMessage,
    chatSocketHandshake: cs.handshake,
    chatSocketDisconnect: cs.disconnect,
    registerVisitorDetails: cs.registerVisitorDetails,

    chatSocketEventListener: (fn) => {
      this.emitChatSocketEvent = fn;
    },

    // customParams: {
    //   agentId: props?.agentId,
    //   agentName: props?.agentName,
    //   offeringIds: props?.offeringIds,
    //   countryCode: props?.countryCode,
    // },

    isChatOpen: false,
    cacheExpiryTime: props.cacheExpiryTime,
    chatCommand: null,
  });

  mountIframe = (elementId) => {
    const props = this.getIframeProps(this.configurations);
    this.componentWidget = ZoidWidgetComponent.getInstance({
      ...props,
      closeChat: this.chatContainer.toggleOpen,
      onRendered: () => {
        // Trigger custom event to indicate that the chat widget has been initialized...
        const initChatReadyEvent = new CustomEvent('d3x-chat:ready');
        window.dispatchEvent(initChatReadyEvent);
      },
    });

    this.componentWidget.render(`#${elementId}`);
  };

  updateIframeProps = (props) => {
    this.componentWidget.updateProps(props);
  };

  handler = (api, params) => {
    if (!api) throw Error('API method required');

    api = api.toLowerCase();

    if (supportedAPI.indexOf(api) === -1)
      throw Error(`Method ${api} is not supported`);

    switch (api) {
      case 'init':
        this.init(params);
        break;

      case 'hide':
        this.chatContainer.hide();
        break;

      case 'show':
        this.chatContainer.show();
        break;

      case 'toggle-open':
        this.chatContainer.toggleOpen();
        break;

      case 'destroy':
        this.chatContainer.destroy();
        this.chatContainer = null;
        cs.disconnect();
        break;

      case 'custom_styles':
        this.chatContainer.resetStylingConfig(params);
        this.chatContainer.setStylingConfig(params);
        break;

      case 'open-with-params':
        this.chatContainer.toggleOpen(true, {
          chatCommand: {
            name: 'open-with-params',
            aiSubtenantCode: params?.subtenant_code,
            skipRegistration: params?.skip_registration || false,
            visitorDetails: {
              fullName: params?.reservation_details?.full_name,
              email: params?.reservation_details?.email,
              phone: params?.reservation_details?.phone,
            },
          },
        });
        break;

      default:
        // eslint-disable-next-line no-console
        console.warn(`No handler defined for ${api}`);
    }
  };
}

function app(window) {
  const globalObjectId =
    window?.['akin-chat-widget'] || window?.['d3x-chat-widget'] || 'cw';

  const globalObject = window[globalObjectId];
  const api = new WidgetAPI();

  const queue = globalObject.q;
  if (queue) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < queue.length; i++) {
      api.handler(queue[i][0], queue[i][1]);
    }
  }

  window[globalObjectId] = api.handler;
}

app(window);
