class WebStorageAPI {
  constructor(type) {
    this.storage = null;

    try {
      if (typeof window !== 'undefined') {
        this.storage = window?.[type] || null;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  getItem(id) {
    try {
      if (!this.storage) return null;
      const value = this.storage.getItem(id);
      return JSON.parse(value);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  setItem(id, value) {
    try {
      if (!this.storage) return null;
      const formattedValue = JSON.stringify(value);
      this.storage.setItem(id, formattedValue);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}

export default new WebStorageAPI('localStorage');
